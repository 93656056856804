import React from 'react';
import ReactDOM from 'react-dom';
import '@alife/iot-studio-theme/index.scss';
import '@alife/iot-studio-theme/theme-css-variables.css';
import { ConfigProvider } from '@ali/wind';
import { EPageState, FIND_PASSWORD_PATH, LOGIN_PATH, LOGOUT_PATH } from '~/constants';
import Login from './Login';
import FindPassword from './FindPassword';
import Loading from '~/views/Loading';


interface IAppState {
  pageState: EPageState;
  pageId?: string;
}
class AccountApp extends React.Component<{}, IAppState> {
  cancelHistoryListener: () => {};

  state: IAppState = {
    pageState: EPageState.NONE,
  };

  async componentDidMount() {
    this.updatePageState();
  }

  updatePageState() {
    let pageState;
    if (location.pathname.endsWith(LOGIN_PATH)) {
      pageState = EPageState.LOGIN;
    }

    if (location.pathname.endsWith(FIND_PASSWORD_PATH)) {
      pageState = EPageState.FIND_PWD;
    }
    this.setState({
      pageState,
    });
  }

  render() {
    const { pageState } = this.state;
    switch (pageState) {
      case EPageState.LOGIN:
        return <Login />;
      case EPageState.FIND_PWD:
        return <FindPassword />;
      default:
        return <Loading />;
    }
  }
}

ReactDOM.render(
  (
    <ConfigProvider prefix="studio-">
      <AccountApp />
    </ConfigProvider>
  ),
  document.getElementById('container'),
);
