import intl from '@alife/iot-intl';
import React from 'react';
import { OA_LOGIN as OALOGIN } from '@alife/iot-bone-login-ability-sdk';
import globalConfig from '@alife/iot-vapp-global-config';
import queryString from 'query-string';
import { OA_DOMAIN_MAP, LOGO_URL, BACKGROUND_IMAGE_URL, FIND_PASSWORD_PATH, LOGIN_PATH } from '~/constants';
import { getFixedPath } from './utils';
import { ILoginConfig } from './Login';
import { OA_LOGIN_LOCALE } from './locale';
import style from './index.scss';

const { env, appKey, appName, pageList } = globalConfig;

enum EFindPwdType {
  PHONE = 'phone',
  EMAIL = 'email',
  RESTPWD = 'resetEmailPassword',
}

const EmailType = '?type=email';

const IframeUrl = {
  [EFindPwdType.PHONE]: `${OA_DOMAIN_MAP[env]}/login/mini_findpwd.htm`,
  [EFindPwdType.EMAIL]: `${OA_DOMAIN_MAP[env]}/login/emailResetPwdStep1.htm`,
  [EFindPwdType.RESTPWD]: `${OA_DOMAIN_MAP[env]}/login/emailResetPwd.htm`,
};
interface IFindPwdState {
  accountType: EFindPwdType;
  loginConfig?: ILoginConfig;
}
export default class FindPwd extends React.Component<{}, IFindPwdState> {
  state: IFindPwdState = {
    accountType: getFindPwdType(),
  };

  componentDidMount() {
    this.loadLoginConfig();
  }

  async loadLoginConfig() {
    const loginPageUrl = (
      pageList.find(item => item.is_login_page) || {}
    ).cdn_url;
    const loginConfig: ILoginConfig = {
      logo: LOGO_URL,
      backgroundImage: BACKGROUND_IMAGE_URL,
      name: appName as string,
    };
    if (loginPageUrl) {
      const configed = await fetch(`${loginPageUrl}?t=${Date.now()}`).then(res => res.json());
      Object.keys(configed).forEach((key) => {
        if (configed[key]) {
          loginConfig[key] = configed[key];
        }
      });
    }
    this.setState({
      loginConfig,
    });
  }


  onLoginSuccess = () => {
  };

  onLoginFindPwd = () => {
  };

  onMessage = (args) => {
    if (args.action && args.resultCode === '100') {
      const { action } = args;
      switch (action) {
        // 手机邮箱找密成功
        case 'findpwd':
        case 'emailResetPwd':
          window.location.href = getFixedPath({
            pathname: LOGIN_PATH,
            // 账号相关的 path 都要带上 redirect_url，避免实例和应用之间的登陆成功之后的链接混乱
            locationSearchKeys: ['token', 'redirect_url'],
          });
          break;
        // 监听切换邮箱找密的消息
        case 'resetPwdSwitch': {
          const newType = this.state.accountType === EFindPwdType.PHONE
            ? EFindPwdType.EMAIL
            : EFindPwdType.PHONE;
          window.location.href = getFixedPath({
            pathname: FIND_PASSWORD_PATH,
            query: {
              type: newType,
            },
            // 账号相关的 path 都要带上 redirect_url，避免实例和应用之间的登陆成功之后的链接混乱
            locationSearchKeys: ['token', 'redirect_url'],
          });
          break;
        }
        default:
      }
    }
  };

  getIframeUrlBySearch = () => {
    const { accountType } = this.state;
    // 如果是邮箱找回密码页面，才需要进行页面query的携带
    if (accountType === EFindPwdType.EMAIL) {
      const urlQuery: any = queryString.parse(window.location.search);
      // 邮箱找回密码会带emailToken,存在时说明是找回邮箱页面过来的
      if (urlQuery.emailToken) {
        // 删除掉不需要的query参数
        ['type', 'token', 'redirect_url'].forEach(name => delete urlQuery[name]);
        return `${IframeUrl[EFindPwdType.RESTPWD]}?${queryString.stringify(urlQuery)}&`;
      }
    }
    return IframeUrl[accountType];
  };

  renderLoginContent() {
    const iframeUrl = this.getIframeUrlBySearch();
    return (
      <OALOGIN
        onLoginSuccess={this.onLoginSuccess}
        onLoginFindPwd={this.onLoginFindPwd}
        onMessage={this.onMessage}
        appKey={appKey}
        iframeUrl={iframeUrl}
        iframeWidth={'800px'}
        iframeHeight={'400px'}
        lang={OA_LOGIN_LOCALE}
        custom={{
          emailEnabled: true,
          styleType: 'vertical',
          notKeepLogin: true,
          addSwitchUrl: true,
          hostUrl: `//${window.location.host}/login/findpwd${EmailType}`,
        }}
      />
    );
  }

  render() {
    const { loginConfig } = this.state;
    if (!loginConfig) {
      return null;
    }
    return (
      <div className={style.loginContainer}>
        <div className={style.head}>
          <img src={loginConfig.logo} />
          <h1>{loginConfig.name}</h1>
        </div>
        <div className={style.findPassWord}>
          <h2>{intl('locale.iot.studio.webapp.renderer.resetPassword')}</h2>
          {this.renderLoginContent()}
        </div>
      </div>
    );
  }
}

function getFindPwdType() {
  return queryString.parse(window.location.search).type as EFindPwdType
    || EFindPwdType.PHONE;
}
