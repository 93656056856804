import intl from '@alife/iot-intl';
import { currentLocale } from './locale';

declare global {
  interface Window {
    __INITIAL_STATE__: Record<string, any>;
  }
}

intl.setLocale(currentLocale.split('-')[0]);
// 参数为美杜莎应用key
intl.register('iot-studio-webapp');

require('./account');
