import intl from '@alife/iot-intl';
import React from 'react';
import Icon from '@alife/iot-vapp-ui-icon';
import styles from './index.scss';

export default function Loading() {
  return (
    <div className={styles.container}>
      <Icon type="icon-loading" className={styles.icon} />
      <span className={styles.content}>
        {intl('locale.iot.studio.webapp.renderer.loadingAzone')}
      </span>
    </div>
  );
}
