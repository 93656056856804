import intl from '@alife/iot-intl';
import React from 'react';
import cx from 'classnames';
import globalConfig from '@alife/iot-vapp-global-config';
import { Tab, Button } from '@ali/wind';
/* 引入页面模型 */
import { OA_LOGIN as OALOGIN } from '@alife/iot-bone-login-ability-sdk';
import { OA_DOMAIN_MAP, LOGO_URL, BACKGROUND_IMAGE_URL, FIND_PASSWORD_PATH } from '~/constants';
import { OA_LOGIN_LOCALE } from './locale';
import { getFixedPath } from './utils';
import styles from './index.scss';

export const CERTIFICATES_KEY = 'certificates';
export const CERTIFICATE_THEME_KEY = 'certificateTheme';

const { env, appKey, appName, pageList } = globalConfig;
export enum EAccountType {
  ACCOUNT = 'account',
  PHONE = 'phone',
}
const tabs = [
  {
    tab: intl('locale.iot.studio.webapp.renderer.accountPasswordLogin'),
    key: EAccountType.ACCOUNT,
  },
  {
    tab: intl('locale.iot.studio.webapp.renderer.mobilePhoneVerification'),
    key: EAccountType.PHONE,
  },
];
const IFRAME_URL_MAP = {
  [EAccountType.ACCOUNT]: `${OA_DOMAIN_MAP[env]}/login/mini_login.htm`,
  [EAccountType.PHONE]: `${OA_DOMAIN_MAP[env]}/login/phoneCode.htm`,
};

export interface LoginPageCertificate {
  icon1: string;

  icon2: string;

  text: string;

  link: string;
}

export interface ILoginConfig {
  name: string;
  logo: string;
  backgroundImage: string;
  [CERTIFICATES_KEY]?: LoginPageCertificate[];
  [CERTIFICATE_THEME_KEY]?: 'light' | 'dark';
}
interface ILoginState {
  accountType: EAccountType;
  loginConfig?: ILoginConfig;
}

// scss module 在编辑会丢失
const titleStyle = {
  overflow: 'hidden' as 'hidden',
  textOverflow: 'ellipsis' as 'ellipsis',
  display: '-webkit-box' as '-webkit-box',
  WebkitBoxOrient: 'vertical' as 'vertical',
  WebkitLineClamp: 2,
};

function CertificateIcon({ src }: { src: string }) {
  return (
    <div className={styles['certificate-icon-container']}>
      <img className={styles['certificate-icon']} src={src} />
    </div>
  );
}

export default class Login extends React.Component<{}, ILoginState> {
  state: ILoginState = {
    accountType: EAccountType.ACCOUNT,
  };

  componentDidMount() {
    this.loadLoginConfig();
  }

  async loadLoginConfig() {
    const loginPageUrl = (
      pageList.find(item => item.is_login_page) || {}
    ).cdn_url;
    const loginConfig: ILoginConfig = {
      logo: LOGO_URL,
      backgroundImage: BACKGROUND_IMAGE_URL,
      name: appName as string,
    };
    if (loginPageUrl) {
      const configed = await fetch(`${loginPageUrl}?t=${Date.now()}`).then(res => res.json());
      Object.keys(configed).forEach((key) => {
        if (configed[key]) {
          loginConfig[key] = configed[key];
        }
      });
    }
    this.setState({
      loginConfig,
    });
  }

  onLoginSuccess = (data) => {
    window.location.href = getFixedPath({
      pathname: '/login/success',
      query: data,
      // 账号相关的 path 都要带上 redirect_url，避免实例和应用之间的登陆成功之后的链接混乱
      locationSearchKeys: ['redirect_url'],
    });
  };

  onLoginFindPwd = () => {
    window.open(getFixedPath({
      pathname: FIND_PASSWORD_PATH,
      // 账号相关的 path 都要带上 redirect_url，避免实例和应用之间的登陆成功之后的链接混乱
      locationSearchKeys: ['token', 'redirect_url'],
    }));
  };

  onMessage = () => {

  };

  renderForgetPwd() {
    return (
      <div className={styles.forgetPassword}>
        <Button
          type="normal"
          text
          onClick={this.onLoginFindPwd}
          className={styles.button}
        >
          {intl('locale.iot.studio.webapp.renderer.forgotPassword')}
        </Button>
      </div>
    );
  }

  renderLoginContent() {
    const { accountType } = this.state;
    return (
      <div className={styles.loginBox}>
        <OALOGIN
          onLoginSuccess={this.onLoginSuccess}
          onLoginFindPwd={this.onLoginFindPwd}
          onMessage={this.onMessage}
          appKey={appKey}
          iframeUrl={IFRAME_URL_MAP[accountType]}
          iframeHeight={210}
          lang={OA_LOGIN_LOCALE}
          custom={{
            width: 360,
            loginIdPlaceHolder: intl('locale.iot.studio.webapp.renderer.pleaseEnterThe'),
          }}
        />
      </div>
    );
  }

  renderTab() {
    return (
      <Tab
        onChange={activeTabKey => this.setState({ accountType: activeTabKey as any })}
        activeKey={this.state.accountType}
        navClassName={styles.nav}
        contentStyle={{ display: 'none' }}
      >
        {tabs.map(tab => <Tab.Item title={tab.tab} key={tab.key} />)}
      </Tab>
    );
  }

  render() {
    const { accountType, loginConfig } = this.state;
    if (!loginConfig) {
      return null;
    }

    const { [CERTIFICATES_KEY]: certificates, [CERTIFICATE_THEME_KEY]: certificateTheme } = loginConfig;

    return (
      <div className={styles.loginContainer} style={{ backgroundImage: `url(${loginConfig.backgroundImage})` }}>
        <img className={styles.logo} src={loginConfig.logo} />
        <div className={styles.logoContainer}>
          <h1 style={titleStyle}>{loginConfig.name || ' '}</h1>
          <div className={styles.content}>
            {this.renderTab()}
            {this.renderLoginContent()}
            {
              accountType === tabs[0].key
              &&
              this.renderForgetPwd()
            }
          </div>
        </div>{Array.isArray(certificates) && certificates.length > 0 && (
          <div className={cx(styles.certificates, styles[`certificates-theme-${certificateTheme}`])}>
            {certificates.map(item => (
              <div className={styles.certificate}>
                {item.icon1 && (<CertificateIcon src={item.icon1} />)}
                {item.icon2 && (<CertificateIcon src={item.icon2} />)}
                {item.link
                  ? (
                    <a
                      className={styles['certificate-link']}
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>{item.text}</span>
                    </a>
                  )
                  : (<span>{item.text}</span>)}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
