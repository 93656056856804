import queryStringLib from 'query-string';
// 脱敏手机号
export function parsePhone(phone) {
  const pat = /(\d{3})\d*(\d{4})/;
  return phone.replace(pat, '$1****$2');
}

// 脱敏邮箱
export function parseEmail(email) {
  const emailLast = email.substring(email.indexOf('@'));
  const firstStr = email.substr(0, 1);
  return `${firstStr}****${emailLast}`;
}
export type Partial<T> = {
  [P in keyof T]?: T[P];
};


interface IGetFixedPathOptions {
  pathname: string;
  // 参数对象
  query?: object;
  // 参数字符串
  queryString?: string;
  // 是否带上当前 location.search
  locationSearch?: boolean;
  // 带上 location.search 的部分 keys，和 locationSearch 二选一
  locationSearchKeys?: string[];
}
/**
 * 获取修复后的 url 地址
 */
export function getFixedPath({
  pathname,
  query,
  queryString,
  locationSearch,
  locationSearchKeys,
}: IGetFixedPathOptions) {
  let data = query || {};
  if (queryString) {
    data = {
      ...data,
      ...queryStringLib.parse(queryString),
    };
  }
  if (locationSearch || locationSearchKeys) {
    const locationParsed = queryStringLib.parse(window.location.search);
    if (locationSearch) {
      data = {
        ...data,
        ...locationParsed,
      };
    } else if (locationSearchKeys) {
      data = {
        ...data,
        ...locationSearchKeys.reduce((obj, key) => {
          if (key in locationParsed) {
            obj[key] = locationParsed[key];
          }
          return obj;
        }, {}),
      };
    }
  }
  return `${pathname}?${queryStringLib.stringify(data)}`;
}
